import React, { forwardRef } from "react";
import * as c from "./button.module.scss";
import classNames from "classnames";
import { Link } from "gatsby";

const Button = forwardRef(
  (
    {
      className,
      size = "medium",
      variant = "full",
      isExternal = false,
      asHtmlButton = false,
      linkTo = "",
      children,
      ...props
    },
    ref
  ) => {
    let Element;
    const linkProps = {};

    if (asHtmlButton) {
      Element = "button";
    } else if (isExternal) {
      Element = "a";
      linkProps.href = linkTo;
      linkProps.target = "_blank";
    } else {
      Element = Link;
      linkProps.to = linkTo;
    }

    const isAnimated = variant !== "link" && variant !== "textgradient";

    const cx = classNames(
      className,
      c.button,
      c[variant],
      c[size],
      isAnimated && c.animated,
      variant === "outline" && c.outline
    );

    return (
      <Element ref={ref} className={cx} {...linkProps} {...props}>
        {children}
      </Element>
    );
  }
);

export { Button };
