import * as Sentry from "@sentry/gatsby";

const setPostHogSessionReplayTag = (sentryEvent) => {
  try {
    const replayUrl = window?.posthog?.get_session_replay_url?.();

    if (replayUrl && sentryEvent) {
      sentryEvent.tags = sentryEvent.tags || {};
      sentryEvent.tags.posthogReplay = replayUrl;

      window.posthog.capture("Sentry Error", { event_id: sentryEvent?.event_id });
    }
  } catch (e) {
    console.warn("Failed to set PostHog session replay tag", e);
  }

  return sentryEvent;
};

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,
  beforeSend: setPostHogSessionReplayTag,
  enabled: process.env.GATSBY_SENTRY_DSN && process.env.CONTEXT === "production",
  integrations: [Sentry.browserTracingIntegration(), Sentry.captureConsoleIntegration({ levels: ["error"] })],
  tracesSampleRate: 0.2, // A number between 0 and 1, controlling the percentage chance a given transaction will be sent to Sentry.
  environment: process.env.CONTEXT, // Corresponds to the current netlify deploy-context or "dev" for non-cloud builds
});
